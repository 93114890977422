import {ModuleWithProviders, NgModule, Optional, SkipSelf} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NbAuthModule, NbPasswordAuthStrategy} from '@nebular/auth';
import {NbRoleProvider, NbSecurityModule} from '@nebular/security';
import {of as observableOf} from 'rxjs';

import {throwIfAlreadyLoaded} from './module-import-guard';
import {AnalyticsService, LayoutService, PlayerService, StateService,} from './utils';

const socialLinks = [
  {
    url: 'https://github.com/akveo/nebular',
    target: '_blank',
    icon: 'github',
  },
  {
    url: 'https://www.facebook.com/akveo/',
    target: '_blank',
    icon: 'facebook',
  },
  {
    url: 'https://twitter.com/akveo_inc',
    target: '_blank',
    icon: 'twitter',
  },
];

const DATA_SERVICES = [];


export class NbSimpleRoleProvider extends NbRoleProvider {
  getRole() {
    // here you could provide any role based on any auth flow
    return observableOf('guest');
  }
}

export const NB_CORE_PROVIDERS = [
  ...DATA_SERVICES,
  ...NbAuthModule.forRoot({

                            strategies: [
                              NbPasswordAuthStrategy.setup({
                                                             baseEndpoint: '/keycloak',
                                                             name: 'email',
                                                             token: {
                                                               key: 'token',
                                                             },
                                                             login: {
                                                               endpoint: '/auth/realms/finsec-project.eu/protocol/openid-connect/token',
                                                               method: 'post',
                                                             },
                                                             register: {
                                                               endpoint: '/protocol/openid-connect/token',
                                                               method: 'post',
                                                               redirect: {
                                                                 success: '/pages/dashboard',
                                                                 failure: null, // stay on the same page
                                                               },
                                                             },
                                                             logout: {},
                                                             refreshToken: {},
                                                             resetPass: {}
                                                           }),

                            ],
                            forms: {}
                          }).providers,

  NbSecurityModule.forRoot({
                             accessControl: {
                               guest: {
                                 view: '*',
                               },
                               user: {
                                 parent: 'guest',
                                 create: '*',
                                 edit: '*',
                                 remove: '*',
                               },
                             },
                           }).providers,

  {
    provide: NbRoleProvider, useClass: NbSimpleRoleProvider,
  },
  AnalyticsService,
  LayoutService,
  PlayerService,
  StateService,
];

@NgModule({
            imports: [
              CommonModule,
            ],
            exports: [
              NbAuthModule,
            ],
            declarations: [],
          })
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

  static forRoot(): ModuleWithProviders {
    return <ModuleWithProviders>{
      ngModule: CoreModule,
      providers: [
        ...NB_CORE_PROVIDERS,
      ],
    };
  }
}
