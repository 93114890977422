import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {LoginComponent} from './login.component';
import {ReactiveFormsModule} from "@angular/forms";

@NgModule({
            imports: [
              CommonModule,
              ReactiveFormsModule,
            ],
            declarations: [LoginComponent]
          })
export class LoginModule {
}
